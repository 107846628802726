var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['coupon-wrap', { 'layout-full-height': !_vm.filteredCardsByStatus.length || !_vm.filteredCards.length }]},[_c('div',{staticClass:"coupon-wrap__has-coupon"},[_c('BaseTabs',{attrs:{"items":[
        _vm.$t('Benefit.tab.Coupons.Avaliable').value,
        _vm.$t('Benefit.tab.Coupons.Used').value,
        _vm.$t('Benefit.tab.Coupons.Expired').value
      ]},on:{"change":_vm.changeCouponStatus}}),_c('BaseTabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredCardsByStatus.length),expression:"filteredCardsByStatus.length"}],attrs:{"shape":"round","items":[
        _vm.$t('Benefit.chip.FeeDiscount.none').value,
        _vm.$t('Benefit.chip.receivingcash.none').value,
        _vm.$t('Benefit.chip.gettingluckybox.none').value
      ]},on:{"change":_vm.changeTabs}}),(_vm.filteredCards.length)?_c('Coupons',{attrs:{"filtered-cards":_vm.filteredCards},on:{"flipCard":_vm.flipCard}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.filteredCardsByStatus.length),expression:"!filteredCardsByStatus.length"}],staticClass:"coupon-not-found"},[_c('img',{attrs:{"srcset":require("@/assets/images/thumb/gray-coupon-1x.png") + " 1x, " + require("@/assets/images/thumb/gray-coupon-2x.png") + " 2x, " + require("@/assets/images/thumb/gray-coupon-3x.png") + " 3x","alt":""}}),_c('p',{staticClass:"pre-line"},[_vm._v(_vm._s(_vm.isVerifyCompleteStatus ? _vm.labels.couponNotFound.status[_vm.currentStatus] : _vm.$t('Benefit.guide.NoMoreCoupons.none').value))]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAvailaleTab && _vm.isVerifyCompleteStatus),expression:"selectedAvailaleTab && isVerifyCompleteStatus"}],attrs:{"block":"","size":"lg","variant":"link"},on:{"click":function($event){_vm.sendToApp('couponBook', { baseUri: _vm.location.origin, path: '/coupon-book', title: _vm.$t('Benefit.button.ReceivingCoupons.None').value })}}},[_c('b',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Benefit.button.ReceivingCoupons.None').value))]),_c('b-icon-chevron-right',{staticClass:"align-middle",attrs:{"font-scale":".8"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredCardsByStatus.length && !_vm.filteredCards.length),expression:"filteredCardsByStatus.length && !filteredCards.length"}],staticClass:"coupon-not-found"},[_c('img',{attrs:{"srcset":require("@/assets/images/thumb/gray-coupon-1x.png") + " 1x, " + require("@/assets/images/thumb/gray-coupon-2x.png") + " 2x, " + require("@/assets/images/thumb/gray-coupon-3x.png") + " 3x","alt":""}}),_c('p',{staticClass:"pre-line"},[_vm._v(_vm._s(_vm.$t('Benefit.guide.NoMoreCoupons.none').value))])]),_c('InputCouponNumber')],1)}
var staticRenderFns = []

export { render, staticRenderFns }