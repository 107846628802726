







































































import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import BaseTabs from '@/components/utils/BaseTabs.vue'
import Coupons from '@/components/Coupons.vue'
import InputCouponNumber from '@/components/InputCouponNumber.vue'
import { filteredCardTypes, sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

import type { IMyCoupon } from '@/types'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    BaseTabs,
    Coupons,
    InputCouponNumber
  },
  setup () {
    const { $t } = useI18n()

    const {
      state: {
        coupon
      },
      getters,
      commit,
      dispatch
    } = store

    const state = reactive({
      $t,
      labels: getters['coupon/labels'],
      currentStatus: computed(() => coupon.currentTabIndex.status),
      currentTabIndex: computed(() => coupon.currentTabIndex.type),
      filteredCardsByStatus: computed(() => {
        return getters['coupon/myCouponByCountry'].filter((card: IMyCoupon) => {
          return filteredCardsByStatus(card)
        })
      }),
      filteredCards: computed(() => {
        return (state.filteredCardsByStatus as IMyCoupon[]).filter((card) => {
          return filteredCardTypes(state.currentTabIndex).includes(card.coupon.settlement_type)
        })
      }),
      isVerifyCompleteStatus: computed(() => getters['auth/isVerifyCompleteStatus']),
      selectedAvailaleTab: computed(() => !state.currentStatus),
      pid: computed(() => getters['auth/$getPid'])
    }) as State

    const filteredCardsByStatus = (card: IMyCoupon) => {
      const tabs = {
        completed: 1,
        expired: 2
      }
      const status = {
        available: 1,
        completed: 2
      }
      const couponStatusfilters = {
        completed: card.status === status.completed,
        expired: card.status !== status.completed &&
          new Date(card.expiration_date).getTime() > 0 &&
          (new Date(card.expiration_date).getTime() <= new Date().getTime())
      }
      const availableCoupon = !couponStatusfilters.completed && !couponStatusfilters.expired
      let filteredCardsByStatus = availableCoupon
      state.currentStatus === tabs.completed && (filteredCardsByStatus = couponStatusfilters.completed)
      state.currentStatus === tabs.expired && (filteredCardsByStatus = couponStatusfilters.expired)
      return filteredCardsByStatus
    }

    const flipCard = (id: string) => {
      const cards = coupon.myCoupons.map((card: IMyCoupon) => {
        return Object.assign({}, card, { coupon: Object.assign({}, card.coupon, { flipped: id === card.id ? !card.coupon.flipped : card.coupon.flipped }) })
      })
      commit('$SET_STATE', { path: 'coupon.myCoupons', value: cards })
    }

    const getCouponCards = async () => {
      await dispatch('coupon/refreshMyCoupons')
      useAmplitudeLogging()
    }

    const changeCouponStatus = (index: number) => {
      commit('$SET_STATE', { path: 'coupon.currentTabIndex.status', value: index })
    }

    const changeTabs = (index: number) => {
      commit('$SET_STATE', { path: 'coupon.currentTabIndex.type', value: index })
    }

    const useAmplitudeLogging = () => {
      sendToApp('view_myCoupon', { count: state.filteredCards.length }, IS_LOGGING)
    }

    getCouponCards()

    return {
      ...toRefs(state),
      flipCard,
      changeCouponStatus,
      changeTabs,
      location,
      sendToApp
    }
  }
})
